import React, { Component } from "react";
import {Link} from 'react-router-dom';

class SideNav extends Component {
  render() {
    const {closeNav} = this.props;
    return (
      <>
        <div className="sideNav">
          <div className="container">
            <div className="cancel" onClick={closeNav}>
              <img src={`${process.env.PUBLIC_URL}/images/cancel-close-svgrepo-com.svg`} alt="" />
            </div>
            <div className="logo">
              <Link to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo2.png`}
                  alt="logo"
                />
              </Link>
            </div>
            <ul className="list-unstyled list">
                <li>
                  <Link className="nav_link" to="/" onClick={closeNav}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/about-us" onClick={closeNav}>
                    About
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/services" onClick={closeNav}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/organizations" onClick={closeNav}>
                    Organizations
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/check-certificate" onClick={closeNav}>
                    Certificates
                  </Link>
                </li>
                {/* <li> <Link className="nav_link" to="/credits"> Credits </Link> </li> */}
                <li>
                  <Link className="nav_link" to="/contactus" onClick={closeNav}>
                    Contact Us
                  </Link>
                </li>
              </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SideNav;
