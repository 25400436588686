import React, { Component } from 'react'
import emailjs from '@emailjs/browser';

import Loading from "../component/Loading";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { Contact_Url } from "../component/Apis";
import Breadcrumb from '../component/Breadcrumb'
import Footer from '../component/Footer';

class ContactUsPage extends Component {

  state = {
    user_name: "",
    phone: "",
    message: "",
    user_email:"",
    loading: false,
  };

  constructor() {
    super();
    this.formRef = React.createRef();
    this.validator = new SimpleReactValidator();
  }

  componentDidMount(){
    document.title = "Contact us";
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    e.preventDefault();
    if (this.validator.allValid()) {
      const state = { ...this.state };
      delete state.loading;
      emailjs.sendForm('service_hy5z6zk', 'template_imlkw8p',this.formRef.current, 'ZZSaxzudXpQgmNZxc')
      .then((result) => {
          console.log(result.text);
          toast.success("Thank you for your message");
          this.setState({
            user_name: "",
            phone: "",
            message: "",
            user_email:"",
            loading: false,
          });
          this.validator.hideMessages();
      }, (error) => {
        toast.error(`please check your contact info`);
        this.setState({
          loading: false,
        });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error(`please check your contact info`);
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { user_name, message, phone,user_email, loading } = this.state;
    return (
      <>
        <Breadcrumb title="CONTACT US" image="contactbr.jpg" />

        <div
          className="contact_us contact_us_innerPage"
          id="contactus"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/contact.jpg')`,
          }}
        >
          <div className="container">
            <div className="contact_header">
              <h5>Let's Talk</h5>
              <p>ask about any thing</p>
            </div>
            <div className="contact_form">
              <form action="" ref={this.formRef} onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="user_name"
                        placeholder="Your name"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={user_name}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "user_name",
                          this.state.user_name,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Your phone Number"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={phone}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "phone",
                          this.state.phone,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="user_email"
                        placeholder="Your email"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={user_email}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "user_email",
                          this.state.user_email,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id=""
                        placeholder="Your inquiry"
                        className="form-control"
                        onChange={this.handelChange}
                        value={message}
                      ></textarea>
                      <span className="error_msg">
                        {this.validator.message(
                          "message",
                          this.state.message,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="btn_submit">
                      <button type="submit" className="btn">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading ? <Loading /> : null}

        <Footer />
      </>
    )
  }
}

export default ContactUsPage