import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAk_x7v15E8mbCL5JoZF9MzUHFMiZEvLzc',
  databaseURL: 'amps-main-default-rtdb.firebaseio.com',
  authDomain: 'amps-main.firebaseapp.com',
  projectId: 'amps-main',
  storageBucket: 'amps-main.appspot.com',
  messagingSenderId: '493349454307',
  appId: '1:493349454307:web:757708aa5aff3cce8260d6',
  measurementId: 'G-TWGTXB08ZY',
};
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export default firebase;
