import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <>
        <div className="header">
          <div className="card_img">
            <div className="img_parent">
              {/* <video src={`${process.env.PUBLIC_URL}/videos/header.mp4`} autoPlay muted playsInline></video> */}
              <img src={`${process.env.PUBLIC_URL}/images/header2.jpg`} alt="" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
