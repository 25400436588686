import axios from "axios";
import React, { Component } from "react";
import { service_Url } from "./Apis";
import Loading from "./Loading";
import ServicesCard from "./ServicesCard";

class Services extends Component {

  state = {
    data: [],
    loading: true,
  }

  componentDidMount=()=>{
    this.getData();
  }

  getData=()=>{
    axios.get(`${service_Url}/`).then((res)=>{
      if(res.status === 200){
        this.setState({
          loading: false,
          data: res.data
        })
      }
    })
  }

  render() {
    const {data, loading} = this.state;
    return (
      <>
        <div className="services" id="services">
          <div className="container">
            <div className="section_header">
              <h6>Our</h6>
              <h5>Services</h5>
            </div>
            <div className="row">

              {data.map((item)=>(
                <div className="col-sm-12 col-md-6 col-lg-4" key={item.id}>
                <ServicesCard
                  title={item.name}
                  image={item.imagePath}
                  pargrapg={item.description}
                />
              </div>
              ))}

            </div>
          </div>
        </div>
        <div className={loading ? null : "removeLoading"}>
          <Loading />
        </div>
      </>
    );
  }
}

export default Services;
