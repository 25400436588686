import React, { Component } from "react";
import AboutUs from "../component/AboutUs";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import Header from "../component/Header";
//import OurCompany from "../component/OurCompany";
import Services from "../component/Services";
//import Loading from "../component/Loading";

class Home extends Component {
  // state = {
  //   loading: true,
  // };

  componentDidMount() {
    document.title = "Home";
    // this.handleLoad()
  }

  // handleLoad = () => {
  //   // console.log("yes");
  //   this.setState({
  //     loading: false,
  //   });
  // };

  render() {
    // const {loading} = this.state;
    return (
      <>
        <Header />

        <AboutUs />

        <Services />

        {/*<OurCompany />*/}

        <ContactUs />

        <Footer />

        {/* <div className={loading ? null : "removeLoading"}>
          <Loading />
        </div> */}
      </>
    );
  }
}

export default Home;
