import React, { Component } from "react";

import {Link} from 'react-router-dom';

class AboutUs extends Component {
  render() {
    return (
      <>
        <div className="about_us_section">
          <div className="container">
            <div className="section_header">
              <h6>About</h6>
              <h5>Us</h5>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className="about_content">
                  <h5>What is American Board ?</h5>
                  <p>
                  American Board Of Professional Studies that aims to create
                leaders in all parts of the world and is considered one of the
                most reliable global professional boards approved by the United
                States of America. An official registration was made when
                registering company numbers in Wyoming, USA, under the
                registration number 2022-001140413
                  </p>
                  <Link to="/about-us" className="readmore">Read More</Link>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-5">
                <div className="about_img">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo2.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
