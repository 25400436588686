import React, { Component } from "react";
import { Bublic_Url } from "./Apis";

class ServicesCard extends Component {
  render() {
    const {image, title, pargrapg} = this.props;  
    return (
      <>
        <div className="services_card">
          <div className="service_img">
            <img src={`${Bublic_Url}/${image}`} alt="" />
          </div>
          <div className="card_body">
            <h5 className="card_title"> {title} </h5>
            <p className="card_text">
             {pargrapg}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesCard;
