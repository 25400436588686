// import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
// import { getDataBySerialNumber } from "../component/Apis";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../component/Loading";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import {db} from "../Firebasenew";
const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      // etc...
    />
  );
};

class CheckCirtificate extends Component {
  state = {
    sNumber: "",
    loading: false,
  };

  componentDidMount() {
    document.title = "Certificate";
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    const state = { ...this.state };
    if (state.sNumber === "") {
      toast.error(`please enter your serial number`);
    } else {
      this.setState({
        loading: true,
      });
      var docRef = db.collection("certs").doc(state.sNumber);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            toast.success(`Success Serial Number`);
            this.props.navigate("/certificate-detials", {
              state: { data:  {
                serial_number:doc.data(state.sNumber).serial_number,
                course_name:doc.data(state.sNumber).course_name.name,
                center_name:doc.data(state.sNumber).center_name.name,
                student_name:doc.data(state.sNumber).student_name.name,
                grade:doc.data(state.sNumber).grade,
                enrolldate:doc.data(state.sNumber).enrolldate,
                course_duration:doc.data(state.sNumber).course_name.duration,
                course_location:doc.data(state.sNumber).course_name.location,
                grade_title:doc.data(state.sNumber).grade_title.name,
              }},
            });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
            toast.error(`No Data Found`);
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          toast.error(`please try again later`);
        });
    }
  };

  render() {
    const { sNumber, loading } = this.state;
    return (
      <>
        <Breadcrumb title="CHECK YOU CERTIFICATE" image="certificate.jpg" />
        <div className="check_certificate">
          <div className="login_wave">
            <img src={`${process.env.PUBLIC_URL}/images/wave2.svg`} alt="" />
          </div>
          {/* <div className="stars">
            { this.stars() }
          </div> */}
          <div className="container">
            <form action="" className="check_form" onSubmit={this.submitForm}>
              <img src={`${process.env.PUBLIC_URL}/images/logo2.png`} alt="" />
              <h5>Check Your Certificate</h5>
              <div className="form-group">
                <input
                  type="text"
                  name="sNumber"
                  placeholder="Write Your Code..."
                  id=""
                  className="form-control"
                  value={sNumber}
                  onChange={this.handelChange}
                />
              </div>
              <div className="btn_submit">
                <button type="submit" className="btn">
                  Check
                </button>
              </div>
            </form>
          </div>
        </div>
        {loading ? <Loading /> : null}
        <Footer />
      </>
    );
  }
}

export default withRouter(CheckCirtificate);
