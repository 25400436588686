import React, { Component } from 'react'
import Breadcrumb from '../component/Breadcrumb'
import Footer from '../component/Footer'
import Services from '../component/Services'

class OurServices extends Component {
  componentDidMount(){
    document.title = "Services";
  }
  render() {
    return (
      <>
        <Breadcrumb title="OUR SERVICES" image="servicesbr.jpg" />

        <div className="inner_page_compnent">
          <Services />
        </div>

        <Footer />
      </>
    )
  }
}

export default OurServices