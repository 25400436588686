import React, { Component } from "react";
import Loading from "./Loading";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { Contact_Url } from "./Apis";

class ContactUs extends Component {
  state = {
    name: "",
    phone: "",
    description: "",
    email:"",
    loading: false,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    e.preventDefault();
    if (this.validator.allValid()) {
      const state = { ...this.state };
      delete state.loading;
      axios
        .post(`${Contact_Url}`, state)
        .then((res) => {
          if (res.status === 201) {
            toast.success("Thank you for your message");
          }
          this.setState({
            loading: false,
            name: "",
            phone: "",
            email: "",
            description: "",
          });
        })
        .catch((error) => {
          toast.error(`please check your contact info`);
          this.setState({
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error(`please check your contact info`);
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { name, phone, description,email, loading } = this.state;
    return (
      <>
        <div
          className="contact_us"
          id="contactus"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/contact.jpg')`,
          }}
        >
          <div className="container">
            <div className="contact_header">
              <h5>Let's Talk</h5>
              <p>ask about any thing</p>
            </div>
            <div className="contact_form">
              <form action="" onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your name"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={name}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Your phone Number"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={phone}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "phone",
                          this.state.phone,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Your email"
                        id=""
                        className="form-control"
                        onChange={this.handelChange}
                        value={email}
                      />
                      <span className="error_msg">
                        {this.validator.message(
                          "email",
                          this.state.email,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <textarea
                        name="description"
                        id=""
                        placeholder="Your inquiry"
                        className="form-control"
                        onChange={this.handelChange}
                        value={description}
                      ></textarea>
                      <span className="error_msg">
                        {this.validator.message(
                          "description",
                          this.state.description,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="btn_submit">
                      <button type="submit" className="btn">
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading ? <Loading /> : null}
      </>
    );
  }
}

export default ContactUs;
