import React, { Component } from "react";
import { Bublic_Url } from "./Apis";

class OrganizationCard extends Component {
  render() {
    const { pargraph, title, image, link } = this.props;
    return (
      <>
        <div className="organization_card">
          <div className="card_logo">
            <img src={`${Bublic_Url}/${image}`} alt="" />
          </div>
          <h5 className="card_title"> {title} </h5>
          <p className="card_text">{pargraph}</p>
          <a href={link} className="organ_link">Visit Site <i class="fas fa-external-link-alt"></i></a>
        </div>
      </>
    );
  }
}

export default OrganizationCard;
